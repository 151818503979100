<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="名称" prop="name">
      <Input v-model="formData.name" placeholder="请输入名称" />
    </FormItem>
    <FormItem v-if="formData.id" label="类型" prop="status">
      <RadioGroup v-model="formData.status">
        <Radio v-for="item in scmCheckPlanStatusList" :label="item.value" :key="item.value" border>{{ item.label }}</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem label="开始时间" prop="startTime">
      <DatePicker type="datetime" :value="formData.startTime" @on-change="time => formData.startTime = time" placeholder="请选择开始时间"></DatePicker>
    </FormItem>
    <FormItem label="结束时间" prop="endTime">
      <DatePicker type="datetime" :value="formData.endTime" @on-change="time => formData.endTime = time" placeholder="请选择结束时间"></DatePicker>
    </FormItem>
  </Form>
</template>

<script>
function getDefault () {
  return {
    id: null,
    name: null,
    status: 0,
    startTime: null,
    endTime: null
  }
}

const rules = Object.freeze({
  name: [
    { required: true, message: '请输入名称' }
  ],
  status: [
    { required: true, message: '请选择状态' }
  ]
})

export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      formData: this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault(),
      rules
    }
  },
  computed: {
    scmCheckPlanStatusList () {
      return this.$store.getters.scmCheckPlanStatusList
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault()
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  }
}
</script>
