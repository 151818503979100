<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem :label="'盘点数量 - 当前数量: ' + formData.baseVolume" prop="volume">
      <InputNumber controls-outside v-model="formData.volume" @on-change="formData.diffVolume = Number((formData.volume - formData.baseVolume).toFixed(6))" />
    </FormItem>
    <FormItem label="盘盈盘亏" prop="diffVolume">
      <Input v-model="formData.diffVolume" />
    </FormItem>
    <FormItem label="备注" prop="remark">
      <Input v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
export function getDefault () {
  return {
    volume: null,
    diffVolume: null,
    remark: null
  }
}

const rules = Object.freeze({
  volume: [
    { required: true, message: '请输入数量' }
  ],
  diffVolume: [
    { required: true, message: '请输入数量' }
  ]
})

function getFormData (data) {
  return {
    baseVolume: data.goodsStockCheckId ? data.hisVolume : data.volume,
    volume: data.volume,
    diffVolume: data.diffVolume || 0,
    remark: null
  }
}

export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      formData: getFormData(this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault()),
      rules
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = getFormData(this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault())
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            let confirm = true
            this.formData.diffVolume = Number((this.formData.volume - this.formData.baseVolume).toFixed(6))
            if (this.formData.diffVolume !== 0) {
              confirm = await this.$confirm({
                title: '系统提示',
                content: this.formData.diffVolume > 0 ? ('确定盘盈' + this.formData.diffVolume + '吗？') : ('确定盘亏' + Math.abs(this.formData.diffVolume) + '吗？')
              })
            }
            if (!confirm) {
              resolve(null)
            }
            resolve({
              volume: this.formData.volume,
              diffVolume: this.formData.diffVolume,
              remark: this.formData.remark
            })
          } else {
            resolve(false)
          }
        })
      })
    }
  }
}
</script>
