<style scoped lang="less">
  .unit-choose-label {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    outline: 0;
    color: #515a6e;
    &.placeholder {
      color: #808695;
    }
  }
  .unit-choose {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdee2;
    cursor: pointer;
    padding: 0 8px;
    display: flex;
    align-items: center;
    transition: all .3s;
    justify-content: space-between;
    .clearable { display: none; }
    &:hover {
      border-color: #57a3f3;
      .clearable { display: block; }
      .clearable + .arrow { display: none; }
    }
  }
</style>

<template>
  <div>
    <div class="unit-choose" @click="openChoose">
      <span :class="{placeholder: !data}" class="unit-choose-label">{{ data ? (data.storehouseName + ' / ' + data.name) : '请选择' }}</span>
      <Icon @click.stop="clear" type="md-close-circle" v-if="clearable" class="clearable" />
      <Icon type="ios-arrow-down" class="arrow" />
    </div>
    <Drawer :z-index="1100" placement="bottom" title="单元选择" :mask-closable="false" closable transfer draggable :height="80" v-model="status.choose">
      <UnitPage style="margin: 0;padding: 0;height: 100%;" @on-choose="onChoose" v-if="status.choose" :storehouseId="storehouseId" choose />
    </Drawer>
  </div>
</template>

<script>
import request from '../../../api'
import UnitPage from '../../../views/stock/unit.vue'
export default {
  components: { UnitPage },
  props: {
    storehouseId: { type: Number, default: null },
    value: { type: Number, default: null },
    clearable: { type: Boolean, default: false },
    transfer: { type: Boolean, default: false }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      loading: false,
      data: null,
      status: {
        choose: false
      }
    }
  },
  watch: {
    value () {
      if (this.data && this.value !== this.data.id) {
        this.loadData()
      }
    }
  },
  methods: {
    clear () {
      this.data = null
      this.$emit('input', null)
    },
    openChoose () {
      this.status.choose = true
    },
    onChoose (data) {
      this.$emit('input', data.id)
      this.data = data
      this.status.choose = false
    },
    async loadData () {
      if (this.value) {
        const data = await request.get('/storehouse/unit', {
          id: this.value
        })
        this.data = data[0]
      } else {
        this.data = null
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
