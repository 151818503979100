<style scoped>
</style>

<template>
  <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="goodsName">名称</Option>
                  <Option value="goodsCode">编码</Option>
                </Select>
              </i-input>
            </i-col>
            <i-col>货物类型: </i-col>
            <i-col>
              <Dropdown @on-click="goodsUseType => onSearch({ goodsUseType })">
                <template v-for="item in scmGoodsUseTypeList">
                  <Tag v-if="String(item.value) === query.goodsUseType" size="large" class="noborder click" color="default" :key="item.value">{{ item.label }}<Icon type="ios-arrow-down"></Icon></Tag>
                </template>
                <Tag size="large" class="noborder click" color="default" v-if="query.goodsUseType === 'all'">全部<Icon type="ios-arrow-down"></Icon></Tag>
                <DropdownMenu slot="list">
                  <DropdownItem v-for="item in scmGoodsUseTypeList" :key="item.value" :selected="query.status === String(item.value)" :name="String(item.value)">{{ item.label }}</DropdownItem>
                  <DropdownItem :selected="query.status === 'all'" name="all">全部</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:goodsStockCheckBatchId="{ row }">
          <Tooltip transfer :content="row.goodsStockCheckBatchStatusData.label">
            <Tag class="noborder" :color="row.goodsStockCheckBatchStatusData.color">
              {{ row.goodsStockCheckBatchName }}
            </Tag>
          </Tooltip>
        </template>
        <template v-slot:goodsId="{ row }">
          <Tooltip transfer :content="row.goodsCode">
            {{ row.goodsName }} - {{ row.goodsGgxh }}
          </Tooltip>
        </template>
        <template v-slot:volume="{ row }">
          {{ row.volume === null ? '-' : row.volume + row.goodsUnit }}
        </template>
        <template v-slot:hisVolume="{ row }">
          {{ row.hisVolume === null ? '-' : row.hisVolume + row.goodsUnit }}
        </template>
        <template v-slot:diffVolume="{ row }">
          {{ row.diffVolume === null ? '-' : row.diffVolume + row.goodsUnit }}
        </template>
        <template v-slot:storehouseId="{ row }">
          {{ row.storehouseName }} / {{ row.storehouseUnitName }}
        </template>
      </Table>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../../components/page/base.vue'
import request from '../../../api/index'

export default {
  components: { AppPage },
  props: {
    planId: { type: Number, default: null }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {
        key: 'goodsName',
        value: null,
        goodsUseType: 'all'
      },
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: []
    }
  },
  computed: {
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    scmCheckPlanStatusList () {
      return this.$store.getters.scmCheckPlanStatusList
    },
    currentPlanId () {
      let planId = this.planId || this.$route.query.planId
      return planId && !isNaN(planId) ? Number(planId) : null
    },
    columns () {
      return [
        { title: '货物', key: 'goodsId', slot: 'goodsId', minWidth: 180, ellipsis: true, tooltip: true },
        { title: '仓库', key: 'storehouseId', slot: 'storehouseId', minWidth: 180, ellipsis: true, tooltip: true },
        // { title: '生产批次', key: 'produceBatch' },
        // { title: '生产日期', key: 'produceTime' },
        // { title: '过期时间', key: 'exceedTime' },
        { title: '盘点数量', key: 'volume', slot: 'volume', width: 140 },
        { title: '盘点人', key: 'userName', width: 140 },
        { title: '盘点确认时间', key: 'checkEndTime', width: 140 },
        { title: '盘点前数量', key: 'hisVolume', slot: 'hisVolume', width: 140 },
        { title: '盘盈盘亏', key: 'diffVolume', slot: 'diffVolume', width: 140 },
        { title: '备注', key: 'remark', ellipsis: true, tooltip: true, minWidth: 160 }
      ]
    },
    searchKey () {
      return [this.currentPlanId].join('-')
    }
  },
  watch: {
    searchKey () {
      if (this.routerName === this.$route.name) {
        this.refresh()
      }
    }
  },
  methods: {
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'goodsName'
      this.query.value = null
      this.query.status = 'all'
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      if (this.currentPlanId) {
        params.goodsStockCheckBatchId = this.currentPlanId
      }

      if (this.query.goodsUseType !== 'all') {
        params.goodsUseType = Number(this.query.goodsUseType)
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock_check', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsStockCheckBatchStatusData = this.scmCheckPlanStatusList.find(v => v.value === item.goodsStockCheckBatchStatus)
          item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    }
  }
}
</script>
