<style scoped></style>

<template>
  <div style="display: flex;">
    <Select style="width: 100px;" v-model="useType" @on-change="onUseTypeChange" placeholder="请选择物品类型">
      <Option v-for="item in scmGoodsUseTypeList" :key="item.value" :value="item.value">{{ item.label }}</Option>
    </Select>
    <Select @on-query-change="onQueryChange" @on-change="onValueChange" style="flex: 1; margin-left: 10px;" filterable :loading="loading" v-model="goodsId" placeholder="请选择物品">
      <template v-if="defaultItem">
        <Option v-show="defaultItem && !query" :value="defaultItem.id">{{ defaultItem.name + ' - ' + defaultItem.ggxh }}</Option>
      </template>
      <Option v-for="item in goodsList" :key="item.id" :value="item.id">{{ item.name + ' - ' + item.ggxh }}</Option>
    </Select>
  </div>
</template>

<script>
import request from '../../../api'
export default {
  props: {
    value: { type: Number, default: null }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      useType: 1,
      goodsId: null,
      loading: false,
      goodsList: [],
      defaultItem: null,
      query: null
    }
  },
  computed: {
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
  },
  watch: {
    value () {
      this.loadData()
    }
  },
  methods: {
    onValueChange (v) {
      if (v !== this.value && v !== undefined) {
        this.$emit('input', v)
      }
    },
    async onUseTypeChange () {
      this.loading = true
      this.$baseList = await request.get('/goods', {
        useType: this.useType
      })
      const value = this.value || this.goodsId
      this.defaultItem = value ? this.$baseList.find(v => v.id === value) : null
      const goodsList = this.$baseList.slice(0, 100)
      this.goodsList = this.defaultItem ? goodsList.filter(v => v.id !== value) : goodsList
      this.loading = false
    },
    onQueryChange (query) {
      this.query = query
      let goodsList = []
      if (query) {
        goodsList = this.$baseList.filter(v => (v.name || '').indexOf(query) > -1).slice(0, 100)
      } else {
        goodsList = this.$baseList.slice(0, 100)
      }
      const value = this.value || this.goodsId
      this.goodsList = value ? goodsList.filter(v => v.id !== value) : goodsList
    },
    async loadData () {
      if (this.value) {
        this.loading = true
        const data = await request.get('/goods', { id: this.value })
        this.loading = false
        if (data.length) {
          this.useType = data[0].useType
          await this.onUseTypeChange()
          this.$nextTick(() => {
            this.goodsId = this.value
          })
        }
      } else {
        this.goodsId = null
        await this.onUseTypeChange()
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
