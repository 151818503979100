<style lang="less" scoped>
  .item-list {
    display: flex;
    flex-wrap: nowrap;
    padding: 5px;
    .wrap {
      padding: 5px;
      width: 25%;
      .item {
        border-radius: 5px;
        border: 1px solid #EEE;
        background-color: #FFF;
        padding: 10px;
        position: relative;
        .remark {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: keep-all;
          width: 100%;
        }
        .title {
          font-weight: bold;
        }
        .successRate {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }
</style>

<template>
  <AppPage :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="name">名称</Option>
                </Select>
              </i-input>
            </i-col>
            <i-col>状态: </i-col>
            <i-col>
              <Dropdown @on-click="status => onSearch({ status })">
                <template v-for="item in scmCheckPlanStatusList">
                  <Tag size="large" style="cursor: pointer;" :key="item.value" :color="item.color" v-if="query.status === String(item.value)">{{item.label}}<Icon type="ios-arrow-down"></Icon></Tag>
                </template>
                <Tag size="large" style="cursor: pointer;" color="default" v-if="query.status === 'all'">全部<Icon type="ios-arrow-down"></Icon></Tag>
                <DropdownMenu slot="list">
                  <DropdownItem :selected="query.status === 'all'" name="all">全部</DropdownItem>
                  <DropdownItem v-for="item in scmCheckPlanStatusList" :key="item.value" :selected="query.status === String(item.value)" :name="String(item.value)">{{ item.label }}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
          <Button style="margin-left: 10px;" v-if="$authFunsProxy.add" @click="itemClick(null)" type="primary" icon="md-add">新增</Button>
        </i-col>
      </Row>
    </template>
    <div class="item-list">
      <div class="wrap" v-for="item in dataList" :key="item.id">
        <div class="item">
          <Row align="middle" justify="space-between">
            <span class="title">{{ item.name }}</span>
            <Tag class="noborder" :color="item.statusData.color">{{ item.statusData.label }}</Tag>
          </Row>
          <Row justify="space-between" style="margin-top: 10px;">
            <i-col>开始时间: </i-col>
            <i-col>
              {{ (item.startTime || '-') }}
            </i-col>
          </Row>
          <Row justify="space-between" style="margin-top: 10px;">
            <i-col>结束时间: </i-col>
            <i-col>
              {{ (item.endTime || '-') }}
            </i-col>
          </Row>
          <Row align="middle" :wrap="false" justify="space-between" style="margin-top: 10px;">
            <i-col v-if="item.status === 1 && $authFunsProxy.deal">
              <Button @click="showDetail(item)" size="small" type="text">确认</Button>
            </i-col>
            <i-col v-if="$authFunsProxy.get">
              <Button @click="showLogs(item)" size="small" type="text">记录</Button>
            </i-col>
            <i-col v-if="$authFunsProxy.update">
              <Button @click="itemClick(item)" size="small" type="text">修改</Button>
            </i-col>
            <i-col v-if="$authFunsProxy.del">
              <Poptip transfer confirm @on-ok="itemDel(item)" title="确定删除吗？">
                <Button :loading="loading.del" size="small" type="text">删除</Button>
              </Poptip>
            </i-col>
          </Row>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <Drawer placement="right" :title="formData && formData.id ? '修改' : '新增'" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
        <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
          <PlanForm
            v-if="status.form"
            :data="formData"
            ref="form"
          />
        </DrawerAction>
      </Drawer>
      <Drawer placement="right" v-if="$authFunsProxy.deal" :title="(formData ? formData.name : '') + ' - 盘点确认'" :mask-closable="false" closable transfer draggable :width="80" v-model="status.detail">
        <PlanChecking style="height: 100%;margin: 0;padding: 0;" v-if="status.detail" :planId="formData ? formData.id : null" />
      </Drawer>
      <Drawer placement="right" v-if="$authFunsProxy.get" :title="(formData ? formData.name : '') + ' - 盘点记录'" :mask-closable="false" closable transfer draggable :width="80" v-model="status.logs">
        <PlanCheckLogs style="height: 100%;margin: 0;padding: 0;" v-if="status.logs" :planId="formData ? formData.id : null" />
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../../components/page/base.vue'
import DrawerAction from '../../../components/drawer/action.vue'
import request from '../../../api/index'
import PlanForm from '../../../components/stock/check/form/plan.vue'
import PlanChecking from './checking.vue'
import PlanCheckLogs from './logs.vue'

export default {
  components: { AppPage, PlanForm, PlanChecking, PlanCheckLogs, DrawerAction },
  props: {},
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 20,
        total: 0
      },
      query: {
        key: 'name',
        value: null,
        status: 'all'
      },
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false,
        detail: false,
        logs: false
      },
      formData: null
    }
  },
  computed: {
    scmCheckPlanStatusList () {
      return this.$store.getters.scmCheckPlanStatusList
    }
  },
  methods: {
    showDetail (item) {
      this.formData = item
      this.status.detail = true
    },
    showLogs (item) {
      this.formData = item
      this.status.logs = true
    },
    itemClick (item) {
      this.formData = item
      this.status.form = true
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (!data) {
          return this.$Message.warning('请完整填写表单')
        }
        this.loading.submit = true
        if (data.id) {
          const status = data.status
          delete data.status
          await request.put('/goods_stock_check/batch/' + data.id, data)
          if (this.formData.id === data.id && this.formData.status !== status) {
            await request.put('/goods_stock_check/batch/status/' + data.id, { status })
          }
        } else {
          delete data.status
          await request.post('/goods_stock_check/batch', data)
        }
        this.$Message.success('操作成功')
        this.status.form = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    async itemDel (item) {
      await request.delete('/goods_stock_check/batch/' + item.id)
      this.$Message.success('已删除')
      this.loadData()
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'name'
      this.query.value = null
      this.query.status = 'all'
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      if (this.query.status !== 'all') {
        params.status = Number(this.query.status)
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock_check/batch', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.statusData = this.scmCheckPlanStatusList.find(v => v.value === item.status)
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
