<style scoped>
</style>

<template>
  <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" :wrap="false" align="middle" style="margin-bottom: 10px;">
        <i-col style="flex: 1; width: 0;overflow-x: auto;white-space: nowrap;">
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select transfer slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="goodsName">名称</Option>
                  <Option value="goodsCode">编码</Option>
                </Select>
              </i-input>
            </i-col>
            <i-col>货物类型: </i-col>
            <i-col>
              <Dropdown transfer @on-click="goodsUseType => onSearch({ goodsUseType })">
                <template v-for="item in scmGoodsUseTypeList">
                  <Tag v-if="String(item.value) === query.goodsUseType" size="large" class="click" color="default" :key="item.value">{{ item.label }}<Icon type="ios-arrow-down"></Icon></Tag>
                </template>
                <Tag size="large" class="click" color="default" v-if="query.goodsUseType === 'all'">全部<Icon type="ios-arrow-down"></Icon></Tag>
                <DropdownMenu slot="list">
                  <DropdownItem v-for="item in scmGoodsUseTypeList" :key="item.value" :selected="query.status === String(item.value)" :name="String(item.value)">{{ item.label }}</DropdownItem>
                  <DropdownItem :selected="query.status === 'all'" name="all">全部</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </i-col>
            <i-col>状态: </i-col>
            <i-col>
              <RadioGroup style="display: flex;flex-wrap: nowrap;" @on-change="onSearch()" v-model="query.status" type="button">
                <Radio label="all">全部</Radio>
                <Radio label="checked">已盘点</Radio>
                <Radio label="checking">待盘点</Radio>
              </RadioGroup>
            </i-col>
            <i-col>仓库\车间:</i-col>
            <i-col>
              <Select transfer clearable v-model="query.storehouseId" @on-change="onSearch()" placeholder="请选择仓库\车间">
                <Option v-for="item in scmStoreHouseList" :key="item.id" :value="item.id">{{ item.name }}</Option>
              </Select>
            </i-col>
            <i-col v-if="query.storehouseId">仓库单元：</i-col>
            <i-col v-if="query.storehouseId">
              <StockUnitChoose transfer clearable @input="onSearch()" :storehouseId="query.storehouseId" v-model="query.storehouseUnitId" />
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
          <Button @click="noStockCheck" style="margin-left: 10px;" :loading="loading.load" icon="md-add" type="primary">盘盈</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:status="{ row }">
          <Tag class="noborder" color="blue" v-if="row.goodsStockCheckId">已盘点</Tag>
          <Tag class="noborder" color="default" v-else>未盘点</Tag>
        </template>
        <template v-slot:goodsId="{ row }">
          <Tooltip transfer :content="row.goodsCode">
            {{ row.goodsName }} - {{ row.goodsGgxh }}
          </Tooltip>
        </template>
        <template v-slot:storehouseId="{ row }">
          {{ row.storehouseName }} / {{ row.storehouseUnitName }}
        </template>
        <template v-slot:volume="{ row }">
          {{ row.volume !== null ? (row.volume + ' ' + row.goodsUnit) : '-' }}
        </template>
        <template v-slot:hisVolume="{ row }">
          {{ row.hisVolume !== null ? (row.hisVolume + ' ' + row.goodsUnit) : '-' }}
        </template>
        <template v-slot:diffVolume="{ row }">
          {{ row.diffVolume !== null ? (row.diffVolume + ' ' + row.goodsUnit) : '-' }}
        </template>
        <template v-slot:checkVolume="{ row }">
          {{ row.checkVolume !== null ? (row.checkVolume + ' ' + row.goodsUnit) : '-' }}
        </template>
        <template v-slot:action="{ row }">
          <Poptip v-if="row.goodsStockCheckId && !loading.submit" style="margin-right: 10px;" transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
            <Button :loading="loading.del" type="warning" size="small">删除</Button>
          </Poptip>
          <Button v-if="!loading.del" :loading="loading.submit" @click="itemCheck(row)" type="primary" size="small">{{ row.goodsStockCheckId ? '重新确认' : '确认' }}</Button>
        </template>
      </Table>
    </template>
    <template v-slot:footer>
      <Drawer placement="right" title="盘点确认" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
        <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
          <CheckingForm type="in" ref="form" :data="formData" />
        </DrawerAction>
      </Drawer>
      <Drawer placement="right" title="盘盈" :mask-closable="false" closable transfer draggable :width="30" v-model="status.noStockCheck">
        <DrawerAction :loading="loading.submit" @confirm="noStockSubmit" @cancel="status.noStockCheck = false">
          <NoStockCheckingForm v-if="status.noStockCheck" ref="noStockForm" />
        </DrawerAction>
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../../components/page/base.vue'
import DrawerAction from '../../../components/drawer/action.vue'
import request from '../../../api/index'
import CheckingForm from '../../../components/stock/check/form/checking.vue'
import NoStockCheckingForm from '../../../components/stock/check/form/noStock.vue'
import StockUnitChoose from '../../../components/stock/choose/unit.vue'

export default {
  components: { AppPage, CheckingForm, NoStockCheckingForm, DrawerAction, StockUnitChoose },
  props: {
    planId: { type: Number, default: null }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {
        key: 'goodsName',
        value: null,
        goodsUseType: 'all',
        status: 'all',
        storehouseId: null,
        storehouseUnitId: null,
      },
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false,
        noStockCheck: false
      },
      formData: null
    }
  },
  computed: {
    scmStoreHouseList () {
      return this.$store.getters.scmStoreHouseList
    },
    scmCheckPlanStatusList () {
      return this.$store.getters.scmCheckPlanStatusList
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    currentPlanId () {
      let planId = this.planId || this.$route.query.planId
      return planId && !isNaN(planId) ? Number(planId) : null
    },
    columns () {
      return [
        { title: '货物', key: 'goodsId', slot: 'goodsId', fixed: 'left', width: 180 },
        { title: '仓库', key: 'storehouseId', slot: 'storehouseId', fixed: 'left', width: 180, ellipsis: true, tooltip: true },
        { title: '当前数量', key: 'volume', slot: 'volume', width: 100 },
        { title: '盘点前数量', key: 'hisVolume', slot: 'hisVolume', width: 120 },
        { title: '盘盈盘亏', key: 'diffVolume', slot: 'diffVolume', width: 100 },
        { title: '盘点人', key: 'checkUserName', width: 100, ellipsis: true, tooltip: true },
        { title: '盘点数量', key: 'checkVolume', slot: 'checkVolume', width: 100 },
        { title: '盘点确认时间', key: 'checkEndTime', minWidth: 130, ellipsis: true, tooltip: true },
        { title: '盘点备注', key: 'checkRemark', ellipsis: true, tooltip: true, width: 160 },
        // { title: '生产批次', key: 'produceBatch', width: 100, ellipsis: true, tooltip: true },
        // { title: '生产日期', key: 'produceTime', minWidth: 130, ellipsis: true, tooltip: true },
        // { title: '过期时间', key: 'exceedTime', minWidth: 130, ellipsis: true, tooltip: true },
        { title: '备注', key: 'remark', ellipsis: true, tooltip: true, width: 160 },
        { title: '状态', key: 'status', slot: 'status', fixed: 'right', width: 80 },
        { title: '操作', key: 'action', slot: 'action', fixed: 'right', width: 180 }
      ]
    },
    searchKey () {
      return [this.currentPlanId].join('-')
    }
  },
  watch: {
    searchKey () {
      if (this.routerName === this.$route.name) {
        this.refresh()
      }
    }
  },
  methods: {
    async noStockSubmit () {
      const data = await this.$refs.noStockForm.getFormData()
      try {
        await request.post('/goods_stock_check/no_stock', Object.assign(data, {
          goodsStockCheckBatchId: this.currentPlanId
        }))
        this.$Message.success('操作成功')
        this.status.noStockCheck = false
        this.refresh()
      } catch (e) {
        this.$Modal.error({
          title: '系统提示',
          content: e.message
        })
      }
    },
    noStockCheck () {
      this.status.noStockCheck = true
    },
    itemCheck (item) {
      this.formData = item
      this.status.form = true
    },
    async itemDel (item) {
      if (item.goodsStockCheckId) {
        this.loading.del = true
        try {
          await request.delete('/goods_stock_check/' + item.goodsStockCheckId)
          this.$Message.success('操作成功')
          this.loadData()
        } catch (e) {
          this.$Modal.error({ title: '系统提示', content: e.message })
        }
        this.loading.del = false
      }
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (data === false) {
          return this.$Message.warning('请完整填写表单')
        }
        if (data === null) {
          return
        }

        let params = {
          goodsStockId: this.formData.id,
          goodsStockCheckBatchId: this.planId,
          volume: data.volume,
          remark: data.remark,
          diffVolume: data.diffVolume
        }

        this.loading.submit = true
        if (this.formData.goodsStockCheckId) {
          await request.put('/goods_stock_check/' + this.formData.goodsStockCheckId, params)
        } else {
          await request.post('/goods_stock_check', params)
        }
        this.$Message.success('操作成功')
        this.status.form = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'goodsName'
      this.query.value = null
      this.query.status = 'all'
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      if (this.currentPlanId) {
        params.goodsStockCheckBatchId = this.currentPlanId
      }

      if (this.query.goodsUseType !== 'all') {
        params.goodsUseType = Number(this.query.goodsUseType)
      }

      if (this.query.storehouseId) {
        params.storehouseId = this.query.storehouseId
      }

      if (this.query.storehouseUnitId) {
        params.storehouseUnitId = this.query.storehouseUnitId
      }

      if (this.query.status === 'checking') {
        params.waitChecking = 1
      }

      if (this.query.status === 'checked') {
        params.checked = 1
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock_check/checking', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsStatusData = this.scmGoodsStatusList.find(v => v.value === item.goodsStatus)
          item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    }
  },
  mounted () {
    this.$store.dispatch('loadStoreHouse')
  }
}
</script>
