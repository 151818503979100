<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="物品" prop="goodsId">
      <GoodsChoose v-model="formData.goodsId" />
    </FormItem>
    <FormItem label="数量" prop="volume">
      <InputNumber controls-outside v-model="formData.volume" @on-change="formData.diffVolume = formData.volume - formData.baseVolume" />
    </FormItem>
    <FormItem label="仓库" prop="storehouseId">
      <Select clearable v-model="formData.storehouseId" placeholder="请选择仓库\车间">
        <Option v-for="item in scmStoreHouseList" :key="item.id" :value="item.id">{{ item.name }}</Option>
      </Select>
    </FormItem>
    <FormItem v-if="formData.storehouseId" label="仓库单元" prop="storehouseUnitId">
      <StockUnitChoose clearable :storehouseId="formData.storehouseId" v-model="formData.storehouseUnitId" />
    </FormItem>
    <FormItem label="备注" prop="remark">
      <Input v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
import GoodsChoose from '../../../goods/base/choose.vue'
import StockUnitChoose from '../../choose/unit.vue'

export function getDefault () {
  return {
    goodsId: null,
    volume: null,
    storehouseId: null,
    storehouseUnitId: null,
    remark: null
  }
}

const rules = Object.freeze({
  goodsId: [
    { required: true, message: '请选择物品' }
  ],
  volume: [
    { required: true, message: '请输入数量' }
  ],
  storehouseId: [
    { required: true, message: '请选择仓库' }
  ],
  storehouseUnitId: [
    { required: true, message: '请选择仓库单元' }
  ]
})

export default {
  components: { GoodsChoose, StockUnitChoose },
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      formData: getDefault(),
      rules
    }
  },
  computed: {
    scmStoreHouseList () {
      return this.$store.getters.scmStoreHouseList
    }
  },
  methods: {
    reset () {
      this.formData = getDefault()
    },
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            resolve({
              goodsId: this.formData.goodsId,
              volume: this.formData.volume,
              storehouseId: this.formData.storehouseId,
              storehouseUnitId: this.formData.storehouseUnitId,
              remark: this.formData.remark
            })
          } else {
            resolve(false)
          }
        })
      })
    }
  },
  mounted () {
    this.$store.dispatch('loadStoreHouse')
  }
}
</script>
